import React from 'react';
import { Grid, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ShopTwoOutlinedIcon from '@mui/icons-material/ShopTwoOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import { useNavigate } from 'react-router-dom';

type IconType = React.ComponentType<{ sx?: React.CSSProperties }>;

interface DashboardCardProps {
    Icon: IconType;
    category: string;
    name: string;
    desc: string;
    navigateTo: string;
}

const DashboardCard: React.FC<DashboardCardProps> = ({ Icon, category, name, desc, navigateTo }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(navigateTo);
    };

    return (
        <Box
            onClick={handleClick}
            sx={{
                marginBottom: 3,
                height: '100%',

                cursor: 'pointer',
                position: 'relative',
                p: 3,
                overflow: 'hidden',
                '&:hover .underline': {
                    width: 200,
                },
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '60px',
                    background: 'linear-gradient(to bottom, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 100%)',
                    zIndex: 1,
                },
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: -85,
                    right: -70,
                    width: 280,
                    height: 160,
                    borderRadius: '50%',
                    backgroundColor: 'rgba(25, 118, 210, 0.2)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                }}
            >
                <Icon sx={{ fontSize: 60, color: 'rgba(117, 117, 117, 0.8)', marginTop: 14, marginLeft: 8 }} />
            </Box>
            <Box sx={{ position: 'relative', zIndex: 2 }}>
                <Typography variant="overline" component="div" sx={{ mb: 1, color: '#757575' }}>
                    {category.toUpperCase()}
                </Typography>
                <Typography variant="h5" component="div" sx={{ mb: 4, fontStyle: "italic", fontWeight: 400, fontFamily: 'Playfair Display', position: 'relative', display: 'inline-block' }}>
                    {name}
                    <Box
                        className="underline"
                        sx={{
                            position: 'absolute',
                            bottom: -9,
                            left: 0,
                            width: '100%',
                            height: 2,
                            backgroundColor: 'primary.main',
                            transition: 'width 0.3s ease',
                        }}
                    />
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {desc}
                </Typography>
            </Box>
        </Box>
    );
};

const VerticalDivider: React.FC = () => (
    <Box
        sx={{
            position: 'absolute',
            right: 0,

            top: '5%',
            bottom: '5%',
            width: '1px',
            bgcolor: '#e0e0e0',
            '&::before, &::after': {
                content: '""',
                position: 'absolute',
                left: 0,
                width: 0,
                height: 0,
                borderLeft: '1px solid transparent',
                borderRight: '1px solid transparent',
            },
            '&::before, &::after, &': {
                background: 'linear-gradient(to bottom, transparent, #e0e0e0 30%, #e0e0e0 70%, transparent)',
            },
        }}
    />
);

export const DashboardCardRender: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const cardData = [
        { icon: AccountCircleOutlinedIcon, category: "Account", name: "Management", navigateTo: "/account", desc: "Manages account entries and reports" },
        { icon: DoNotDisturbAltIcon, category: "Diamonds", name: "Non-Certified", navigateTo: "/non-certified-diamonds", desc: "Manages non-certificated diamonds" },
        { icon: CurrencyRupeeIcon, category: "Finance", name: "Payment", navigateTo: "/payment", desc: "Payment dues (credit/debit)" },
        { icon: ShopTwoOutlinedIcon, category: "Inventory", name: "Purchase", navigateTo: "/purchase", desc: "Store purchased diamonds" },
        { icon: SummarizeOutlinedIcon, category: "Analytics", name: "Report", navigateTo: "/report", desc: "View reports chronologically" },
        { icon: SellOutlinedIcon, category: "Sales", name: "Sell", navigateTo: "/sell", desc: "Update sold diamonds" },
        { icon: Inventory2OutlinedIcon, category: "Inventory", name: "Stock", navigateTo: "/stock", desc: "Manage stocked diamonds" },
        { icon: GppGoodOutlinedIcon, category: "Inventory", name: "Jewelery", navigateTo: "/jewelery", desc: "Purchased Jewelery Inventory" },
    ];

    return (
        <Grid container spacing={isMobile ? 2 : 0} sx={{ marginBottom: 3 }}>
            {cardData.map((card, index) => (
                <Grid item lg={3} md={4} sm={6} xs={12} key={index} sx={{ position: 'relative' }}>
                    <Box sx={isMobile ? { border: '1px solid #e0e0e0', borderRadius: '4px' } : {}}>
                        <DashboardCard
                            Icon={card.icon}
                            category={card.category}
                            name={card.name}
                            navigateTo={card.navigateTo}
                            desc={card.desc}
                        />
                    </Box>
                    {!isMobile && (index + 1) % 4 !== 0 && <VerticalDivider />}
                </Grid>
            ))}
        </Grid>
    );
}

export default DashboardCardRender;