import React, { useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Box,
    IconButton,
    Collapse,
    Divider,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { tableSpec } from "../Constants/FilterFormItems";
import { Color } from "../Theme/Color";

interface Partner {
    partner_name: string,
    partner_percentage: number
}
interface StockData {
    last_stock_id: string;
    shape?: string;
    size_start: number;
    size_end: number;
    color?: string;
    intensity: string;
    overtone: string;
    fancy_color: string;
    clarity?: string;
    cut_type: string;
    cut_from: string;
    cut_to: string;
    polish_from: string;
    polish_to: string;
    symmetry_from: string;
    symmetry_to: string;
    fluorescence?: string;
    color_fluorescence: string;
    location: string; // default: "India"
    weight: number;
    kapan_number: string;
    lab_reports?: string;
    length: number;
    width: number;
    height: number;
    diameter: number;
    depth: number;
    table_size: number;
    girdle: number;
    age: number;
    cr_angle: number;
    cr_height: number;
    p_angle: number;
    p_depth: number;
    comment: string;
    // partners: Partner[];
    invoice_number: string;
    // deal_date: Date;
    deal_date: string;

    brokerage: number;
    broker: string;
    party: string;
    party_name: string;
    certificate_id: string;
    cur_rap: number;
    price: number;
    invoice_cmnt: string;
    purchase_back: number;
    purchase_rate: number;
    in_stock: number
}

interface StockSize {
    [key: string]: number;
}

const stockSize: StockSize = {
    deal_date: 1.5,
    last_stock_id: 2,
    shape: 1,
    size_start: 1.5,
    size_end: 1.5,
    color: 1,
    clarity: 1,
    price: 1,
    location: 1.5,
    weight: 1,
    intensity: 1,
    overtone: 1,
    fancy_color: 1,
    cut_type: 1,
    cut_from: 1,
    cut_to: 1,
    polish_from: 1,
    polish_to: 1,
    symmetry_from: 1,
    symmetry_to: 1,
    fluorescence: 1,
    color_fluorescence: 1,
    kapan_number: 1,
    lab_reports: 1,
    length: 1,
    width: 1,
    height: 1,
    diameter: 1,
    depth: 1,
    table_size: 1,
    girdle: 1,
    age: 1,
    cr_angle: 1,
    cr_height: 1,
    p_angle: 1,
    p_depth: 1,
    comment: 12,
    partner_name: 1,
    partner_percentage: 1,
    invoice_number: 1,
    party_name: 1,
    brokerage: 1,
    broker: 1,
    party: 1,
    certificate_id: 1,
    cur_rap: 1,
    invoice_cmnt: 1,
    purchase_back: 1,
    purchase_rate: 1,
};
const capitalizeFirstLetter = (text: string) => {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
};
const StockDataCard = ({ stockData }: { stockData: StockData }) => {
    console.log("RAM", stockData)

    const [expanded, setExpanded] = useState<boolean>(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const formatDate = (dateInput: string | number | Date) => {
        const date = new Date(dateInput);

        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };
    const dealDate = formatDate(stockData.deal_date);

    return (
        <Card sx={{ width: "100%", boxShadow: 3, borderRadius: 2, mb: 2, position: "relative", margin: isMobile ? 0 : undefined }}>
            {stockData.in_stock === 0 && (
                <Box
                    sx={{
                        position: "absolute",
                        alignItems: 'center',
                        top: 9,
                        right: -9,
                        backgroundColor: "error.main",
                        color: "white",
                        padding: "4px 20px",
                        transform: "rotate(25deg)",
                        zIndex: 1,
                        borderRadius: 1,
                        boxShadow: 2,
                    }}
                >
                    <Typography variant="body2">
                        Sold
                    </Typography>
                </Box>
            )}

            <CardContent sx={{ margin: 0 }}>
                {/* Date and Icon */}
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <CalendarTodayIcon sx={{ fontSize: 17, mr: 1 }} />
                    <Typography variant="caption" color="text.secondary" fontSize={{ xs: 12, sm: 14, md: 15 }}>
                        {dealDate}
                    </Typography>
                </Box>

                {/* Main Grid Container */}
                <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
                    {tableSpec.slice(1, 9).map((key, index) => {
                        const displayValue =
                            key === "size_start" || key === "size_end"
                                ? `${stockData["size_start"] || "-"} - ${stockData["size_end"] || "-"}`
                                : stockData[key as keyof StockData];

                        if (key === "size_end") return null;

                        const displayKey = key === "size_start" || key === "size_end" ? "size" : key.replace(/_/g, " ");

                        return (
                            <Grid
                                item
                                xs={6} // Ensure two items per row on mobile
                                sm={4} // Three items per row on tablets
                                md={3} // Four items per row on desktops
                                lg={stockSize[key]} // Use provided size for larger screens
                                key={index}
                                textAlign="center"
                            >
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: { xs: 14, sm: 16, md: 18 } }}>
                                    {displayValue || "-"}
                                </Typography>
                                <Typography variant="caption" color="text.secondary">
                                    {capitalizeFirstLetter(displayKey)}
                                </Typography>
                            </Grid>
                        );
                    })}

                    {/* Icon Button */}
                    <Grid item xs="auto" sx={{ justifyContent: "flex-end", alignSelf: "center" }}>
                        <IconButton
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            sx={{ justifyContent: "right", transition: "transform 0.3s", transform: expanded ? "rotate(180deg)" : "rotate(0deg)" }}
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                {/* Expanded View */}
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Grid container rowSpacing={2} columnSpacing={2} sx={{ mt: 2 }} justifyContent="center">
                        {Object.keys(stockData)
                            .filter((key) => key !== "__v" && key !== "id" && key !== "deal_date" && key !== "user_email" && key !== "in_stock")
                            .slice(6)
                            .map((key) => {
                                const typedKey = key as keyof StockData; // Type assertion for 'keyof StockData'

                                return (
                                    <Grid
                                        item
                                        xs={12} // Full width on mobile
                                        sm={5.5} // Two items per row on tablets
                                        key={key}
                                        sx={{
                                            display: "flex",
                                            flexDirection: isMobile ? "column" : "row",
                                            justifyContent: "space-between",
                                            borderBottom: "1px solid grey",
                                            paddingBottom: 1,
                                        }}
                                    >
                                        <Typography variant="subtitle1">
                                            {capitalizeFirstLetter(key.replace(/_/g, " "))}
                                        </Typography>
                                        <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">
                                            {typedKey === "certificate_id" ? (
                                                <a
                                                    href={`https://www.gia.edu/report-check?reportno=${stockData[typedKey]}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ textDecoration: "underline", color: 'blue' }}
                                                >
                                                    {stockData[typedKey] || "-"}
                                                </a>
                                            ) : (
                                                stockData[typedKey] || "-"
                                            )}
                                        </Typography>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Collapse>
            </CardContent>
        </Card>
    );
};

export default StockDataCard;
