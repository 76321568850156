import React, { useContext, useState } from "react";
import FilterForm from "../../../Component/FilterForm";

import { Box, Button, FormControl, Grid } from "@mui/material";


// import { PurchaseContext, PurchaseContextProvider } from "../Context/purchaseProviderContext";
import axios from "axios";
import { setUserData } from "../../../store/slice/userSlice";
import { snackbarType } from "../../../Component/CustomSnackBar";
import { PurchaseContextProvider } from "../../Purchase/Context/purchaseProviderContext";
import JeweleryStockNumber from "../Components/JeweleryStockNumber";
import JeweleryDetails from "../Components/JeweleryDetails";



const PurchaseJewelery: React.FC = () => {



    return (
        <PurchaseContextProvider>


            <Box sx={{ flexGrow: 1, display: "flex" }}>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <JeweleryStockNumber />
                    </Grid>
                    <Grid item xs={12}>
                        <JeweleryDetails /> 
                    </Grid>



                </Grid>
            </Box>
        </PurchaseContextProvider>

    );
};

export default PurchaseJewelery;
function dispatch(arg0: any) {
    throw new Error("Function not implemented.");
}

