import { FormControl, Grid, TextField, FormLabel } from '@mui/material';
import React, { ChangeEvent, useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useLocation } from 'react-router-dom';
import { PurchaseContext } from '../../Purchase/Context/purchaseProviderContext';

export default function JeweleryStockNumber() {
    const urllocation = useLocation();
    const { filterFormState, filterFormDispatch } = useContext(PurchaseContext);
    const user = useSelector((state: RootState) => state.user.data);

    // Generate updated stock ID based on user data
    const numericPart = user?.last_stock_id && parseInt(user?.last_stock_id.replace(/\D/g, '')) || 0;
    const incrementedNumericPart = numericPart + 1;
    const updatedStockId = user?.last_stock_id && `${user.last_stock_id.replace(/\d+/g, '')}${incrementedNumericPart}`;

    // Input change handler
    const handleInputChange = (fieldName: string) => (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        filterFormDispatch({
            type: 'SET_FILTER_FORM',
            payload: {
                ...filterFormState,
                [fieldName]: value
            }
        });
    };

    return (
        <FormControl
            fullWidth
            sx={{
                background: 'linear-gradient(145deg, #FAF0BE, #E6C229)', // Light golden gradient
                padding: 2,
                borderRadius: 3,
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                mt: urllocation.pathname === "/purchase" ? 10 : 8,
                border: '1px solid rgba(218, 165, 32, 0.3)' // Soft goldenrod border
            }}
        >
            <FormLabel sx={{
                mb: 2,
                color: '#8B4513', // Saddle brown for a warm text color
                fontWeight: 'bold',
                fontSize: '1.1rem',
                textShadow: '1px 1px 2px rgba(218, 165, 32, 0.2)' // Subtle golden text shadow
            }}>
                Stock Information
            </FormLabel>
            <Grid container spacing={2} mb={2}>
                {urllocation.pathname === "/jewelery" && <>
                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: 'rgba(255, 253, 208, 0.9)', // Very light golden background
                                    borderRadius: 2,
                                    boxShadow: 'inset 0 2px 4px rgba(218, 165, 32, 0.1)',
                                    '& fieldset': {
                                        borderColor: 'rgba(218, 165, 32, 0.5)' // Goldenrod border
                                    }
                                }
                            }}
                            fullWidth
                            label="Last Stock ID"
                            type="text"
                            defaultValue={user?.last_stock_id}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: 'rgba(255, 253, 208, 0.95)',
                                    borderRadius: 2,
                                    boxShadow: 'inset 0 2px 4px rgba(218, 165, 32, 0.1)',
                                    '& fieldset': {
                                        borderColor: 'rgba(218, 165, 32, 0.5)' // Goldenrod border
                                    }
                                }
                            }}
                            fullWidth
                            label="Current Stock ID"
                            type="text"
                            value={updatedStockId}
                        />
                    </Grid>

                </>}

                {urllocation.pathname === "/stock" && <>
                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: 'rgba(255, 253, 208, 0.9)',
                                    borderRadius: 2,
                                    boxShadow: 'inset 0 2px 4px rgba(218, 165, 32, 0.1)',
                                    '& fieldset': {
                                        borderColor: 'rgba(218, 165, 32, 0.5)' // Goldenrod border
                                    }
                                }
                            }}
                            fullWidth
                            label="Stock ID - (STCK-XXXX)"
                            type="text"
                            value={filterFormState.stock_id || ''}
                            onChange={handleInputChange('stock_id')}
                        />
                    </Grid>

                </>}
            </Grid>
        </FormControl>
    );
}